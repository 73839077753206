import { toast } from "react-toastify";

export default class ErrorToast {
  static notifyError(message, code) {
    toast.error(message + " (#" + code + ")", {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}
