import Cookies from "js-cookie";

export default class CookieService {
  static callbacks = null;

  static get(name) {
    return Cookies.get(name);
  }

  static set(name, value) {
    const result = Cookies.set(name, value);
    if (this.callbacks) {
      this.callbacks(name);
    }
    return result;
  }

  static replaceCurrentObserverWith(cb) {
    this.callbacks = cb;
  }

  static delete(name) {
    const result = Cookies.set(name, "");
    if (this.callbacks) {
      this.callbacks(name);
    }
    return result;
  }
}
