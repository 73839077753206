import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enFile from "../constants/locales/en.json";
import esFile from "../constants/locales/es.json";

const Constants = require("../constants/Constants");

// the translations
const resources = {
	en: {
		translation: enFile,
	},
	es: {
		translation: esFile,
	},
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: Constants.DEFAULT_LANGUAGE,

		keySeparator: ".", // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
